import React, { useState, useEffect } from "react";
import "./downline.scss";
import axios from "axios";
import { Button } from "primereact/button";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Badge } from "primereact/badge";
export const Downline = () => {
  const [data, setData] = useState([]);
  const actuser = localStorage.getItem("mgsactuser");
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.post(
        url + "/user/gettreedata",
        {
          actuser,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );

      if (resp.status === 200) {
        setData(resp.data.userdata);
        //setBalance(balance.data.balance);
      }
    };
    fetchData();
  }, [actuser, apikey, url]);
  const createChild = (index, memberid, e) => {
    e.currentTarget.className = "p-button p-button-danger";
    e.currentTarget.disabled = true;
    const fetchData = async () => {
      const resp = await axios.post(
        url + "/user/gettreedata",
        {
          actuser: memberid,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        var tempdata = resp.data.userdata;
        for (let j = 0; j < tempdata.length; j++) {
          setData((prevArray) => {
            const newArray = [...prevArray];
            newArray.splice(index * 1 + 1, 0, resp.data.userdata[j]);
            return newArray;
          });
        }
      }
    };
    fetchData();
  };
  if (!data) return false;
  var rows = [];
  for (let i = 0; i < data.length; i++) {
    rows.push(data[i]);
  }
  console.log(data)
  return (
    <div className="downline">
      <div className="container">
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">Member Id</TableCell>
                <TableCell align="center">Join Under</TableCell>
                <TableCell align="center">Down</TableCell>
                <TableCell align="center">My Fund</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow
                  key={row.memberid}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                  style={{ backgroundColor: `#${row.color_code}` }}
                >
                  <TableCell align="center">
                    <Badge value={row.memberid} />
                  </TableCell>
                  <TableCell align="center">
                    <Badge value={row.join_under_id} />
                  </TableCell>
                  <TableCell align="center">
                    {row.mydown > 0 ? (
                      <Button
                        label={row.mydown}
                        id={row.email}
                        onClick={(e) => createChild(index, row.mem_address, e)}
                      />
                    ) : (
                      <Button label="0" id={row.email} severity="danger" />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <Badge value={row.myfund} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};
