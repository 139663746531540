import React, { useState, useRef, useEffect } from "react";
import "./othermember.scss";
import { Card } from "primereact/card";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import * as Yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { ethers } from "ethers";
import abi from "../../../abi.json";
export const OtherMember = () => {
  const contract = "0x4df8a874b9A6C3E35EA9f0131D7355a932D596E8";
  const toast = useRef(null);
  const Navigate = useNavigate();
  const [isButtonDisabled, setButtonDisabled] = useState(-1);
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const [visible, setVisible] = useState(false);
  const [oneusdt, setOneUsdt] = useState(0);
  const [useraddress, setUserAddress] = useState("");
  const [balance, setBalance] = useState(0);
  const [newid, setNewId] = useState("0");
  useEffect(() => {
    const fetchData = async () => {
      if (window.ethereum) {
        const adrs = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const wa = adrs[0];
        setUserAddress(wa.toLowerCase());
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        var bal = await provider.getBalance(wa);
        bal = ethers.utils.formatEther(bal);
        setBalance(bal);
        const resp = await axios.get(url + "/user/getsoarx", {
          headers: {
            "x-api-key": apikey,
          },
        });
        let data = resp.data.data;
        let srxdetail = await reqSoarx(30, data.usdt_price, data.soarx_price);
        if (bal * 1 < srxdetail.reqsrx) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "You do not have sufficient balance to signup",
          });
        } else {
          setButtonDisabled(0);
        }
        formik.setFieldValue("soarx", srxdetail.reqsrx);
        setOneUsdt(srxdetail.oneusdt);
      }
    };
    fetchData();
  }, []);
  const validationSchema = Yup.object({
    spoid: Yup.number("Invalid Sponsor Id").required("Field Empty!!"),
    newadd: Yup.mixed().required("Field is empty!"),
    usdt: Yup.number("Invalid Value!!! Please Check")
      .required("Invalid Value!!! Please Check.")
      .min(30, "Value Mustbe >= 30 USDT"),
    soarx: Yup.number("Invalid Value!!! Please Check").required(
      "Invalid Value!!! Please Check"
    ),
  });
  const formik = useFormik({
    initialValues: {
      spoid: 1,
      newadd:"",
      usdt: 30,
      soarx: 0,
    },
    validationSchema,
    onSubmit: async (values) => {
      var rnd = Math.floor(100000 + Math.random() * 900000);
      setButtonDisabled(true);
      const resp = await axios.post(
        url + "/user/actother",
        {
          values,
          useraddress,
          rnd,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        //setVisible(true);
        //toast.current.show({ severity: 'success', summary: "Success", detail: "Sign up successfull." });
        let tranId = rnd * 1;
        var soar = values.soarx * 1;
        soar = soar.toString();
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();
        var mycontract = new ethers.Contract(contract, abi, signer);
        mycontract
          .membership(tranId, values.spoid, values.spoid, values.newadd, {
            value: ethers.utils.parseUnits(soar, "ether"),
          })
          .then(function (done) {
            var timerID = setInterval(async function () {
              var user = await mycontract.Users(values.newadd);
              var userId = parseInt(user[0]);
              if (userId != 0) {
                alert(userId);
                clearInterval(timerID);
                axios
                  .post(
                    url + "/user/confactother",
                    {
                      tranId,
                      _newId: userId,
                      _spid: values.spoid,
                      _placeid: values.spoid,
                      _newuseradd: values.newadd,
                      _usdt: values.usdt,
                    },
                    {
                      headers: {
                        "x-api-key": apikey,
                      },
                    }
                  )
                  .then(function (response) {
                    if (response.status == 200) {
                      setNewId(userId);
                      setVisible(true);
                      //setButtonDisabled(false);
                    } else {
                      toast.current.show({
                        severity: "error",
                        summary: "Error",
                        detail: "Some Thing Went Wrong.",
                      });
                    }
                  });
              }
            }, 1000);
          })
          .catch(function (error) {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "You have cancled Transaction.",
            });
            setButtonDisabled(0)
          });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: resp.data.error,
        });
        setButtonDisabled(0)
      }
    },
  });
  const reqSoarx = async (usdt, usdtprice, srxprice) => {
    var reqSoar = (usdtprice / srxprice) * usdt;
    reqSoar = reqSoar.toFixed(0);
    var gas = (usdtprice / srxprice) * 0.5;
    var oneusdt = (usdtprice / srxprice) * 1;
    gas = gas.toFixed(0);
    return {
      reqsrx: reqSoar,
      gasFee: gas,
      total: reqSoar * 1 + gas * 1,
      oneusdt: oneusdt,
    };
  };
  const footerContent = (
    <div>
      <Button
        label="Ok"
        icon="pi pi-check"
        onClick={() => setVisible(false)}
        autoFocus
      />
    </div>
  );
  const usdtChanged = (usdtval) => {
    formik.setFieldValue("usdt", usdtval);
    var soarx = (oneusdt * usdtval).toFixed(0);
    formik.setFieldValue("soarx", (oneusdt * usdtval).toFixed(0));
    if (balance * 1 < soarx * 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Insufficient Balance",
      });
      setButtonDisabled(-1);
    } else {
      setButtonDisabled(0);
    }
    //setTotsoarx((data.oneusdt * usdtval).toFixed(0) * 1 + data.gasfee * 1);
  };
  const soarxChanged = (soarx) => {
    formik.setFieldValue("soarx", soarx);
    formik.setFieldValue("usdt", parseInt((1 / oneusdt) * soarx));
    if (balance * 1 < soarx * 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Insufficient Balance",
      });
      setButtonDisabled(-1);
    } else {
      setButtonDisabled(0);
    }
  };
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  return (
    <div className="othermember">
      <Toast ref={toast} />
      <div className="content">
        <div className="grid">
          <div className="col-12 md:col-12 lg:col-4 left p-0">
            <Card>
              <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit} autoComplete="off">
                  <div className="form-group">
                    <img src="/images/logo.png" width="100" alt="" />
                    <h3>CREATE NEW MEMBER</h3>
                    <div className="component">
                      <div>SPONSOR ID</div>
                      <InputText
                        type="number"
                        name="spoid"
                        placeholder="SPONSOR ID"
                        autoFocus
                        onChange={formik.handleChange}
                        value={formik.values.spoid}
                      />
                      {getFormErrorMessage("spoid")}
                    </div>
                    <div className="component">
                      <div>Receipt Link</div>
                      <InputText
                        type="text"
                        name="newadd"
                        placeholder="Receipt Link"
                        onChange={formik.handleChange}
                        value={formik.values.newadd}
                      />
                      {getFormErrorMessage("newadd")}
                    </div>
                    <div className="component">
                      <div>USDT</div>
                      <InputText
                        type="number"
                        name="usdt"
                        placeholder="USDT"
                        onChange={(e) => usdtChanged(e.target.value)}
                        value={formik.values.usdt}
                      />
                      {getFormErrorMessage("usdt")}
                    </div>
                    <div className="component">
                      <div>SOARX</div>
                      <InputText
                        type="number"
                        name="soarx"
                        placeholder="SOARX"
                        onChange={(e) => soarxChanged(e.target.value)}
                        value={formik.values.soarx}
                      />
                      {getFormErrorMessage("soarx")}
                    </div>

                    <div className="component">
                      {isButtonDisabled === -1 ? (
                        <Button type="button" disabled>
                          Not Possible
                        </Button>
                      ) : (
                        <>
                          {isButtonDisabled === 0 ? (
                            <Button type="submit">Submit</Button>
                          ) : (
                            <>
                              {isButtonDisabled === 1 ? (
                                <Button
                                  type="button"
                                  variant="outlined"
                                  icon="pi pi-spin pi-spinner"
                                >
                                  {" "}
                                  Submitting..
                                </Button>
                              ) : (
                                <Button
                                  type="button"
                                  variant="outlined"
                                  icon="pi pi-check-circle"
                                >
                                  {" "}
                                  Submited
                                </Button>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </Form>
              </FormikProvider>
            </Card>
          </div>
          <div className="col-12 md:col-12 lg:col-8 right">
            <div className="p-1 border-round-sm"></div>
          </div>
        </div>
      </div>
      <Dialog
        visible={visible}
        modal
        header="Success"
        footer={footerContent}
        style={{ width: "50rem" }}
        onHide={() => {
          if (!visible) return;
          setVisible(false);
        }}
      >
        <p className="m-0">Congratulation Your Id Is : {newid}</p>
      </Dialog>
    </div>
  );
};
