import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import "./style.scss";
import { DarkModeContext } from "./context/darkModeContext";
import React, { useContext } from "react";
import { Home } from "./pages/home/Home";
import { HomeAppbar } from "./components/home/HomeAppbar";
import { HomeFooter } from "./components/home/HomeFooter";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css"
import { Signup } from "./pages/home/Signup";
import { Login } from "./pages/home/Login";
import { UserDashBoard } from "./pages/user/userdashboard/UserDashBoard";
import { UserAppBar } from "./components/user/UserAppBar";
import { SelfStack } from "./pages/user/selfinvest/SelfStack";
import { OtherMember } from "./pages/user/othermember/OtherMember";
import { OtherTopup } from "./pages/user/othertopup/OtherTopup";
import { Downline } from "./pages/user/downline/Downline";
import { MyBusiness } from "./pages/user/mybusiness/MyBusiness";
import { Withdraw } from "./pages/user/withdraw/Withdraw";
function App() {
  const { darkMode } = useContext(DarkModeContext);
  const HomeLayout = () => {
    return (
      <div className={`theme-${darkMode ? "dark" : "light"}`}>
        <HomeAppbar />
        <Outlet />
        <HomeFooter />
      </div>
    );
  };
  const UserLayout = () => {
    return (
      <div className={`theme-${darkMode ? "dark" : "light"}`}>
        <UserAppBar />
        <Outlet />
        <HomeFooter />
      </div>
    );
  };
  const router = createBrowserRouter([
    {
      path: "/",
      element: <HomeLayout />,
      children: [
        { path: "/", element: <Home /> },
        { path: "/signup/:spid", element: <Signup /> },
        { path: "/login", element: <Login /> },
        // { path: "/adminlogin", element: <AdminLogin /> },
        
      ]
    },
    {
      path: "/user",
      element: <UserLayout />,
      children: [
        { path: "/user/", element: <UserDashBoard /> },
        { path: "/user/selfstack", element: <SelfStack /> },
        { path: "/user/othermember", element: <OtherMember /> },
        { path: "/user/othertopup", element: <OtherTopup /> },
        { path: "/user/downline", element: <Downline /> },
        { path: "/user/mybusiness", element: <MyBusiness /> },
        { path: "/user/withdraw", element: <Withdraw /> },
        // { path: "/user/mylevels", element: <MyLeves /> },
        // { path: "/user/requesttopupinr", element: <RequestTopUpInr /> },
        // { path: "/user/incomedetails", element: <IncomeDetails /> },
        // { path: "/user/settings", element: <Settings /> },
        // { path: "/user/p2ptransfer", element: <P2PTransfer /> },
        // { path: "/user/manageweekly", element: <ManageWeekly /> },
        // { path: "/user/managemonthly", element: <ManageMonthly /> },      
        // { path: "/user/myaccount", element: <MyAccount /> },      
        // { path: "/user/userprofile", element: <UserProfile /> },      
        // { path: "/user/cryptodeposit", element: <CryptoDeposit /> },      
        // { path: "/user/livebalancereq", element: <LiveBalanceReq /> },      
    ],
    },
    // {
    //   path: "/admin",
    //   element: <AdminLayout />,
    //   children: [
    //     { path: "/admin/", element: <AdminDashboard /> },
    //     { path: "/admin/walletrequest", element: <WalletRequest /> },
    //     { path: "/admin/credituser", element: <CreditUser /> },      
    // ],
    // },
  ])
  return (
    <RouterProvider router={router} />
  );
}

export default App;
