import React, { useRef } from "react";
import "./login.scss";
import { Card } from "primereact/card";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import axios from "axios";
import { Toast } from "primereact/toast";
export const Login = () => {
  const toast = useRef(null);
  const Navigate = useNavigate();
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const autoLogin = async ()=>{
    if (window.ethereum) {
      const adrs = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const wa = adrs[0];
      const resp = await axios.post(url + "/login",{wa},{
        headers: {
          "x-api-key": apikey,
        }
      })
      if(resp.status===200){
        localStorage.setItem("mgsactuser", wa);
        Navigate("/user");
      }else{
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Something Went Wrong.",
        });
      }
    }
  }
  return (
    <div className="login">
      <Toast ref={toast} />
      <div className="content">
        <div className="grid">
          <div className="col-12 md:col-12 lg:col-4 left p-0">
            <Card>
              <div className="form-group">
                <img src="/images/logo.png" width="200" alt="" />
                <h3>LOG IN</h3>
                <div className="component">
                  <Button type="submit" label="Auto Login" size="large" onClick={()=>autoLogin()} />
                </div>
                <div className="component">
                  <p>
                    Have Not Registered?<Link to="/signup/25278">Sign Up</Link>
                  </p>
                </div>
              </div>
            </Card>
          </div>
          <div className="col-12 md:col-12 lg:col-8 right">
            <div className="p-1 border-round-sm"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
