import React, { useEffect, useRef, useState } from "react";
import { Form, FormikProvider, useFormik } from "formik";
import { Card } from "primereact/card";
import axios from "axios";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import "./selfstack.scss";
import moment from "moment";
import { ethers } from "ethers";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import abi from "../../../abi.json";
export const SelfStack = () => {
  const contract = "0x4df8a874b9A6C3E35EA9f0131D7355a932D596E8";
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const [data, setData] = useState({});
  const toast = useRef(null);
  const [isButtonDisabled, setButtonDisabled] = useState(-1);
  const [avlsoarx, setAvlSoarx] = useState(0);
  const [avlusdt, setUsdt] = useState(0);
  const [oneusdt, setOneUsdt] = useState(0);
  const actuser = localStorage.getItem("mgsactuser");
  const [useraddress, setUserAddress] = useState("");
  const initialized = useRef(false);
  const reqSoarx = async (usdt, usdtprice, srxprice) => {
    var reqSoar = (usdtprice / srxprice) * usdt;
    reqSoar = reqSoar.toFixed(0);
    var gas = (usdtprice / srxprice) * 0.5;
    var oneusdt = (usdtprice / srxprice) * 1;
    gas = gas.toFixed(0);
    return {
      reqsrx: reqSoar,
      gasFee: gas,
      total: reqSoar * 1 + gas * 1,
      oneusdt: oneusdt,
    };
  };
  useEffect(() => {
    const fetchData = async () => {
      if (window.ethereum) {
        const adrs = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const wa = adrs[0];
        setUserAddress(wa.toLowerCase());
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        var bal = await provider.getBalance(wa);
        bal = ethers.utils.formatEther(bal);
        bal = (bal * 1).toFixed(2);
        setAvlSoarx(bal);
        const resp = await axios.get(url + "/user/getsoarx", {
          headers: {
            "x-api-key": apikey,
          },
        });
        let data = resp.data.data;
        let srxdetail = await reqSoarx(30, data.usdt_price, data.soarx_price);
        setUsdt((bal / srxdetail.oneusdt).toFixed(2));
        if (bal * 1 < srxdetail.reqsrx) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "You do not have sufficient balance to signup",
          });
        }else{
            setButtonDisabled(0)
        }
        formik.setFieldValue("stacksoarx", srxdetail.reqsrx);
        setOneUsdt(srxdetail.oneusdt);
        const mystack = await axios.post(
          url + "/user/mystacks",
          {
            actuser,
          },
          {
            headers: {
              "x-api-key": apikey,
            },
          }
        );
        setData(mystack.data.mystacks);
      }
    };
    if (!initialized.current) {
      initialized.current = true;
    fetchData();
    }
  }, []);
  const usdtChanged = (usdtval) => {
    formik.setFieldValue("stackusdt", usdtval);
    var soarx = (oneusdt * usdtval).toFixed(0);
    formik.setFieldValue("stacksoarx", (oneusdt * usdtval).toFixed(0));
    if (avlsoarx * 1 < soarx * 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Insufficient Balance",
      });
      setButtonDisabled(-1)
    }else{
        setButtonDisabled(0)
    }
    //setTotsoarx((data.oneusdt * usdtval).toFixed(0) * 1 + data.gasfee * 1);
  };
  const soarxChanged = (soarx) => {
    formik.setFieldValue("stacksoarx", soarx);
    formik.setFieldValue("stackusdt", parseInt((1 / oneusdt) * soarx));
    if (avlsoarx * 1 < soarx * 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Insufficient Balance",
      });
      setButtonDisabled(-1)
    }else{
        setButtonDisabled(0)
    }
  };
  const validationSchema = Yup.object({
    stacksoarx: Yup.number("Invalid Value Found").required(
      "Field cant not be blank."
    ),
    stackusdt: Yup.number("Invalid Value Found")
      .required("Field cant not be blank.")
      .min(30),
  });
  const formik = useFormik({
    initialValues: {
      stacksoarx: 0,
      stackusdt: 30,
    },
    validationSchema,
    onSubmit: async (values) => {
      var rnd = Math.floor(100000 + Math.random() * 900000);
      setButtonDisabled(1);
      const resp = await axios.post(
        url + "/user/stackinit",
        {
          values,
          useraddress,
          rnd,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        let memid = resp.data.memid;
        let tranId = rnd * 1;
        var soar = values.stacksoarx * 1;
        soar = soar.toString();
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();
        var mycontract = new ethers.Contract(contract, abi, signer);
        mycontract
          .memberstack(tranId, useraddress, memid, {
            value: ethers.utils.parseUnits(soar, "ether"),
          })
          .then(function (done) {
            axios
              .post(
                url + "/user/stackconfirm",
                {
                  values,
                  useraddress,
                  rnd,
                },
                {
                  headers: {
                    "x-api-key": apikey,
                  },
                }
              )
              .then(function (respponse) {
                if (respponse.status === 200) {
                  toast.current.show({
                    severity: "success",
                    summary: "Success",
                    detail: "Your Stacking is Successfull",
                  });
                  setButtonDisabled(2);
                } else {
                  toast.current.show({
                    severity: "error",
                    summary: "Error",
                    detail: "Some Thing Went Wrong.",
                  });
                  setButtonDisabled(0);
                }
              });
          });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: resp.data.error,
        });
      }
    },
  });
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  if (!data) return false;
  var rows = [];
  for (let i = 0; i < data.length; i++) {
    rows.push(data[i]);
  }
  return (
    <div className="selfstack">
      <Toast ref={toast} />
      <div className="grid">
        <div className="col-12 md:col-12 lg:col-6 left">
          <Card title="New Stack">
            <FormikProvider value={formik}>
              <Form onSubmit={formik.handleSubmit} autoComplete="off">
                <div className="form-group">
                  <div className="avail">
                    Available Balance: {avlsoarx} : {avlusdt}
                  </div>
                  <div>
                    <div>Stack Soarx</div>
                    <InputText
                      type="number"
                      name="stacksoarx"
                      className="p-inputtext-sm"
                      placeholder="Top Up Value"
                      onChange={(e) => soarxChanged(e.target.value)}
                      value={formik.values.stacksoarx}
                    />
                    {getFormErrorMessage("stacksoarx")}
                  </div>
                  <div>
                    <div>Stack USDT</div>
                    <InputText
                      type="number"
                      name="stackusdt"
                      className="p-inputtext-sm"
                      placeholder="Top Up Value"
                      onChange={(e) => usdtChanged(e.target.value)}
                      value={formik.values.stackusdt}
                    />
                    {getFormErrorMessage("stackusdt")}
                  </div>
                  {isButtonDisabled===-1?
                  <Button type="button" disabled>Not Possible</Button>:
                  <>
                  {isButtonDisabled === 0 ? (
                    <Button type="submit">Submit</Button>
                  ) : (
                    <>
                      {isButtonDisabled === 1 ? (
                        <Button
                          type="button"
                          variant="outlined"
                          icon="pi pi-spin pi-spinner"
                        >
                          {" "}
                          Submitting..
                        </Button>
                      ) : (
                        <Button
                          type="button"
                          variant="outlined"
                          icon="pi pi-check-circle"
                        >
                          {" "}
                          Submited
                        </Button>
                      )}
                    </>
                  )}
                  </>
                  }
                  
                </div>
              </Form>
            </FormikProvider>
          </Card>
        </div>
        <div className="col-12 md:col-12 lg:col-6 right">
          <Card title="My Stack List">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">SL. No.</TableCell>
                    <TableCell align="center">Date</TableCell>
                    <TableCell align="center">Amount</TableCell>
                    <TableCell align="center">Status</TableCell>
                    {/* <TableCell align="center">Claim</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow
                      key={row._id.toString()}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {moment(row.tran_date)
                          .utcOffset(330)
                          .format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center">{row.pool_amt}</TableCell>
                      <TableCell align="center">
                        {row.stop_pay == 0 ? "Running" : "Stoped"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </div>
      </div>
    </div>
  );
};
