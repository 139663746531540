import React, { useState, useRef, useEffect } from "react";
import "./userdashboard.scss";
import { Divider } from "primereact/divider";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import axios from "axios";
import { Link } from "react-router-dom";
export const UserDashBoard = () => {
  const toast = useRef(null);
  const [copy, setCopy] = useState(0);
  const [data, setData] = useState({});
  const initialized = useRef(false);
  const actuser = localStorage.getItem("mgsactuser");
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.post(
        url + "/user/getdetails",
        {
          actuser,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      const respinvestor = await axios.post(
        url + "/user/claiminvest",
        { actuser },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        setData(resp.data.userdata);
        //setBalance(balance.data.balance);
      }
    };

    if (!initialized.current) {
      initialized.current = true;
      fetchData();
    }
  }, [actuser, apikey, url]);
  const copyText = (x) => {
    navigator.clipboard.writeText(x);
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: "Ref Link copied.",
    });
    setCopy(1);
  };
  console.log(data.mydown);
  return (
    <div className="userdashboard">
      <Toast ref={toast} />
      <div className="hero">
        <div className="welcome">WELCOME TO</div>
        <div className="title">
          <span className="mg">MEGA</span>
          <span className="so">SOARX</span>
        </div>
        <Divider />
        <div className="description">ENJOY ADMIN LESS DEFI PLATFORM</div>
        <Divider />
        <div className="myref">
          <div>REF</div>
          <div>
            <span>https://megasoarx.com/signup/{data.memberid}</span>
            {copy === 0 ? (
              <i
                className="pi pi-copy copy"
                onClick={() =>
                  copyText(`https://megasoarx.com/signup/${data.memberid}`)
                }
              ></i>
            ) : (
              <i className="pi pi-check-circle"></i>
            )}
          </div>
        </div>
        <div className="mydown">
          <div>Downline</div>
          {data.mydown == 0 ? (
            <Button label="0" />
          ) : (
            <Link to="/user/downline">
              <Button label={data.mydown} />
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};
