import React from "react";
import "./home.scss";
import {Divider} from "primereact/divider"
import {Button} from "primereact/button"
import { Link } from "react-router-dom";
export const Home = () => {
  return (
    <div className="home">
      <div className="hero">
        <div className="welcome">WELCOME TO</div>
        <div className="title"><span className="mg">MEGA</span><span className="so">SOARX</span></div>
        <Divider />
        <div className="description">ENJOY ADMIN LESS DEFI PLATFORM</div>
        <Divider />
        <div className="button">
          <Link to="/login"><Button icon="pi pi-users" label="Login" className="mr-3" /></Link>
         <Link to="/signup/25278"><Button icon="pi pi-user-plus" severity="warning" label="Register" /></Link>
        </div>
      </div>
    </div>
  );
};
