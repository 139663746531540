import React, { useContext } from "react";
import "./userappbar.scss";
import { Router, useNavigate } from "react-router-dom";
import { Menubar } from "primereact/menubar";
import { DarkModeContext } from "../../context/darkModeContext";
import { Button } from "primereact/button";
export const UserAppBar = () => {
  const Navigate = useNavigate();
  const { toggle, darkMode } = useContext(DarkModeContext);
  const logout = () => {
    localStorage.clear();
    Navigate("/");
  };
  const items = [
    {
      label: "HOME",
      icon: "pi pi-home",
      url: "/user/",
    },
    {
      label: "SELF STACK",
      icon: "pi pi-user",
      url: "/user/selfstack",
    },
    {
      label: "OTHER ID",
      icon: "pi pi-user-plus",
      url: "/user/othermember",
    },
    {
      label: "OTHER TOPUP",
      icon: "pi pi-warehouse",
      url: "/user/othertopup",
    },
    {
        label:"BUSINESS",
        icon: "pi pi-building-columns",
        items: [
            {
              label: "MY BUSINESS",
              icon: "pi pi-face-smile",
              url: "/user/mybusiness",
            },
            {
              label: "STACT REWARD",
              icon: "pi pi-face-smile",
              url: "/user/stackreward",
            },
            {
              label: "REFRERAL REWARD",
              icon: "pi pi-user-edit",
              url: "/user/refreward",
            },
            {
              label: "WITHDRAW",
              icon: "pi pi-user-edit",
              url: "/user/withdraw",
            },
          ],
    }
  ];
  const start = (
    <div className="flex align-items-center gap-1">
      <img alt="logo" src="/images/logo.png" height="40" className="mr-2" />
      <span className="headlogo">MEGA SOARX</span>
    </div>
  );
  const end = (
    <div className="flex align-items-center gap-1">
      {darkMode ? (
        <span
          className="pi pi-moon"
          onClick={toggle}
          style={{ cursor: "pointer" }}
        />
      ) : (
        <span
          className="pi pi-sun"
          onClick={toggle}
          style={{ cursor: "pointer" }}
        />
      )}
      <Button
        label="Logout"
        icon="pi pi-power-off"
        size="small"
        severity="danger"
        className="ml-3"
        onClick={() => logout()}
      />
    </div>
  );
  return (
    <div>
      <div className="card homeappbar">
        <Menubar model={items} start={start} end={end} />
      </div>
    </div>
  );
};
